import lightGallery from 'lightgallery';
// import lgVimeoThumbnail from 'lightgallery/plugins/vimeoThumbnail';
import lgVideo from 'lightgallery/plugins/video';
// import lgThumbnail from 'lightgallery/plugins/thumbnail'

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lg-thumbnail.css'

document.querySelectorAll('.nav').forEach(
	el => el.addEventListener('click', e => {
		e.preventDefault();
		const hash = e.target.getAttribute('href');
		const next = document.querySelector(hash);
		let prev = document.querySelector('.target') || document.querySelector(':target');
		if (prev && prev.classList.contains('hide')) prev = undefined;

		if(history.pushState) {
			history.pushState(null, null, hash);
		}
		else {
			location.hash = hash;
		}	
		document.querySelector('.buttons').scrollIntoView({
            behavior: 'smooth'
        });	
		document.querySelectorAll('.collapsed section').forEach(el => {
			el.classList.remove('target');
			el.classList.add('hide');
		})
		document.querySelectorAll('.buttons .button')
			.forEach(el => el.classList.remove('active'))
		if (next != prev) {
			next.classList.add('target');
			next.classList.remove('hide');
			e.target.classList.add('active');
		}
	})
)

document.querySelectorAll('.total').forEach(
	el => el.closest('form').addEventListener('change', e => {
		el.innerHTML = [...el.closest('form').querySelectorAll('[data-cost]')]
			.reduce((val, el) => {
				let newVal = el.type == 'checkbox' ? el.checked : el.value
				return val + el.getAttribute('data-cost') * newVal
			}, 0)
	})
)

document.querySelectorAll('.ifpost').forEach(el => el.classList.add('hide'))
document.querySelectorAll('[name=post]').forEach(
	elCheck => elCheck.addEventListener('change', e => 
		elCheck.closest('form').querySelectorAll('.ifpost').forEach(
			elHide => elHide.classList[elCheck.checked ? 'remove' : 'add']('hide')
		)
	)
)

let tel = (new URL(document.location)).searchParams.get('pimtel')
if (tel) document.querySelector('#pimtel').innerHTML = tel

let turnstileDone = function (type) {
	console.log(`turnstile ${type} requested`);
	return () => {
		let el = document.querySelector(`#${type} .cf-turnstile`);
		console.log(`turnstile end ${type} activated`);
		console.log(el);
		el.classList.add('collapse')
	}
}
window.turnstileDoneKoop = turnstileDone('koop')
window.turnstileDoneReserveer = turnstileDone('reserveer')
window.turnstileDoneBoek = turnstileDone('boek')
window.turnstileDoneDoneer = turnstileDone('doneer')

for (el of document.getElementsByClassName('gallerij')) {
	console.log(el);
	lightGallery(el, {
		thumbnail: false,
		animateThumb: false,
		zoomFromOrigin: false,
		allowMediaOverlap: true,
		download: false,
		...(el.classList.contains('gallerijvideo') && {
			plugins: [lgVideo],
			vimeoPlayerParams: {
				color: '515946',
				muted: 0,
				byline: 0,
				title: 1,
			}
		})
	});
}
